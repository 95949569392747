.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top:10px;
  // opacity: 0;
  &.normal{
    position: relative;
    top: 0px;
  }
  &.type1{
    top:10vh;
  }
  &.type2{
    top:5vh;
  }
  // opacity: 0.05;
  .logo{
    position: relative;
    width: 60%;
    max-width: 400px;
    margin: auto;
    img{
      position: relative;
      width: 100%;
    }
  }
  .head_container{
    position: relative;
    width: calc(100% - 100px);
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
      .activity_name{
        display: inline-flex;
        font-family: 'MontserratBold';
        font-size: 25px;
        width: 350px;
        // text-overflow: ellipsis;
        white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .user_list{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #000000;
        padding: 10px 30px;
        border-radius: 40px;
        cursor: pointer;
        .user_icons{
          display: flex;
          flex-direction: row;
          align-items: center;
          .profile{
            margin-left: -10px;
            &:first-child{
              margin-left: 0px !important;
            }
          }
          
        }
        
        &:last-child{
          margin-right: -10px;
        }
       /*  :last-child{
          display: none;
        } */
        .remaining_total{
          font-family: 'MontserratBold';
          font-size: 20px;
          color: #ffed8d;
          margin-left: 20px;
          margin-right: -10px;
          display: none;
          &.active{
            display: block;
          }
        }
      }
    .right_controls{
      display: flex;
      flex-direction: row;
      flex-direction: row;
      width: 350px;
      justify-content: flex-end;
      #volume_btn{
        position: relative;
        height: 25px;
        margin-right: 10px;
        display: flex;
        border:1px solid #000000;
        border-radius: 20px;
        padding: 5px 15px;
        cursor: pointer;
        img{
          position: relative;
          height: 100%;
        }
      }
      #screen_resize{
        position: relative;
        height: 25px;
        margin-right: 10px;
        display: flex;
        border:1px solid #000000;
        border-radius: 20px;
        padding: 5px 15px;
        cursor: pointer;
        img{
          position: relative;
          height: 100%;
        }
      }
      #leave_btn{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #000000;
        padding: 5px 20px;
        border-radius: 20px;
        cursor: pointer;
        img{
          position: relative;
          height: 25px;
          display: block;
          margin-right: 10px;
        }
        .label{
          font-family: 'MontserratRegular';
          font-size: 18px;
          color: #ffffff;
        }
      }
    }
  }
}
.students_full_list{
  position: absolute;
  top:70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
    flex-direction: column;
    align-items: center;
  .triangle{
    width: 0;
  height: 0;
  border-left: 20px solid transparent;  /* Half of the desired base width */
  border-right: 20px solid transparent; /* Half of the desired base width */
  border-bottom: 30px solid #000000;  /* Height of the triangle, adjust as*/
  }
  .full_list{
    .empty_room_info{
      font-family: 'MontserratRegular';
      font-size: 16px;
      color: #ffffff;
    }
    background-color: #000000;
    padding: 40px 20px;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.vertical{
      flex-direction: column;
      width: 200px;
      .profile{
   
        &:nth-child(2n+1){
          flex:1 0 calc(50% - 10px);
          margin: 10px 5px;
        }
        // justify-content: center;
      }
      .studentName{
        flex: none;
      }
    }
    // flex-grow: 2;
    width: 500px;
    max-height: 250px;
    overflow-y: auto;
    justify-content: space-between;
    .column_break{
      position: fixed;
      top:50%;
      left:50%;
      transform: translate(-50%, -45%);
      height: 80%;
      width: 1px;
      background-color: #4a4a4a;
    }
    .profile{
      flex:1 0 calc(50% - 10px);
      margin: 10px 5px;
      // width: 50%;
        &:nth-child(2n+1){
          flex:1 0 calc(50% - 50px);
          margin: 5px 5px 5px 25px;
      }
    }
   
  }
  ::-webkit-scrollbar {
    display: none;
  }
 
}
