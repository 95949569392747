@import '../global.scss';
.register_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100%);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    @include for-mobile-portrait-screen{
        flex-direction: column;
        justify-content: space-evenly;
    }
    .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 40%;
        @include for-mobile-portrait-screen{
            width: 100%;
            justify-content: center;
            margin-bottom: 20px;
        }
        .logo{
            position: relative;
            width: 80%;
            max-width: 870px;
            @include for-mobile-portrait-screen{
                width: 60%;
            }
            img{
                position: relative;
                width: 100%;
            }
        }
    }
    .right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60%;
        @include for-mobile-portrait-screen{
            width: 100%;
        }
        .register_area{
            background-color: #684d94;
            border-radius: 20px;
            padding: 50px 80px;
            width: calc(100% - 200px);
            max-width: 400px;
            @include for-mobile-portrait-screen{
                width: calc(100% - 100px);
                padding: 30px 40px;
                max-width: 600px;
            }
            .head{
                .title{
                    font-family: 'MontserratBold';
                    color: #ffffff;
                    font-size: 40px;
                    @include for-mobile-portrait-screen{
                        font-size: 7vw;
                        line-height: 7.5vw;
                    }
                }
                .redirect_sec{
                    font-family: 'MontserratLight';
                    color: #ffffff;
                    font-size: 16px;
                    @include for-mobile-portrait-screen{
                        font-size: 3.5vw;
                    }
                    a{
                        font-family: 'MontserratBold';
                        color: #ffed8d;
                    }
                }
                margin-bottom: 20px;
            }
            .content{
                .field{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: transparent !important;
                    border-bottom: 1px solid #d4d4d4;
                    margin-bottom: 15px;
                    position: relative;
                    ::placeholder{
                        color: #ffffff !important;
                    }
                    .icon{
                        position: relative;
                        height: 25px;
                        img, svg{
                            position: relative;
                            height: 100%;
                            *{
                                fill: #ffffff;
                            }
                        }
                        margin-right: 10px;
                    }
                    .icon2{
                        position: absolute;
                        height: 25px;
                        right: 0;
                        cursor: pointer;
                        img, svg{
                            position: relative;
                            height: 100%;
                            *{
                                fill: #ffffff;
                            }
                        }
                        margin-right: 10px;
                    }
                    input{
                        width: 100%;
                        background-color: transparent !important;
                        outline: none;
                        border: 0;
                        font-family: 'MontserratLight';
                        color: #ffffff;
                        font-size: 16px;
                        line-height: 40px;
                        @include for-mobile-portrait-screen{
                            font-size: 4vw;
                            line-height: 8vw;
                        }
                    }
                    ::placeholder{
                        color: #ffffff;
                    }
                }
                .terms_sec{
                    display: flex;
                    flex-direction: row;
                    .check{
                        position: relative;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        input{
                            position: relative;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }
                    }
                    .label{
                        font-family: 'MontserratLight';
                        color: #ffffff;
                        font-size: 16px;
                        @include for-mobile-portrait-screen{
                            font-size: 3vw;
                        }
                        u{
                            text-underline-offset: 3px;
                            cursor: pointer;
                        }
                    }
                    margin-bottom: 40px;
                    @include for-mobile-portrait-screen{
                        margin-bottom: 20px;
                    }
                }
                .password_hint{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 0px auto 0 auto;

                    .hint{
                        width: 24%;
                        height: 5px;
                        border-radius: 10px;
                        background-color: #f26d7d;
                    }
                }
                .password_hint_txt{
                    font-family: 'MontserratLight';
                    color: #ffffff;
                    font-size: 14px;
                    margin: 5px auto 10px auto;
                }
                .cookie_notice{
                    font-family: 'MontserratLight';
                    color: #ffffff;
                    font-size: 14px;
                    text-align: center;
                    margin-top: 20px;
                    cursor: pointer;
                    @include for-mobile-portrait-screen{
                        margin-top: 10px;
                    }
                }
                .buttons{
                    .signup_error{
                        font-family: 'MontserratLight';
                        color: #ff0000;
                        font-size: 14px;
                        text-align: center;
                        margin-top: 10px;
                    }
                    .divider{
                        margin: 10px 0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        span.dash{
                            width: 25%;
                            height: 2px;
                            border-radius: 10px;
                            background-color: #ffffff;
                        }
                        .or{
                            font-family: 'MontserratLight';
                            color: #ffffff;
                            font-size: 16px;
                            margin: 0 5px;
                        }
                    }
                }
                // .button{

                // }
            }
        }
    }
}


